import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import emailjs from "emailjs-com";
import { Link, useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import validator from "validator";

import OpenForm from "./OpenForm";
import FormModal from "./modal/FormModal";
import "./index.css";
import ParticleComponent from "./ParticleComponent";

function Business() {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ourServices, setOurServices] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [emailError, setEmailError] = useState("");

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleChange = (e) => {
    setOurServices(e.target.value);
  };

  const validateEmail = (e) => {
    var email = e.target.value;
    // console.log(validator.isEmail(email));
    setEmail(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      setEmailError("");
      setName("");
      setEmail("");
      setPhoneNumber("");
      setOurServices("");
      setSubject("");
      setMessage("");

      emailjs
        .sendForm(
          "service_syhdxfo",
          "template_gjiadh4",
          e.target,
          "0gLpe-VwWhjw4Rrc3"
        )
        .then(
          function (response) {
            // return setOpen(true);
            navigate("/thankyou");
            // console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            if (error) {
              // return setError(true)
              navigate("/*");
            }
            // console.log("FAILED...", error);
          }
        );
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  return (
    <div className="business" id="business">
      <div className="pb-5">
        <ParticleComponent className="particles-style" />
        <div>
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2 align-items-center">
              <div className="col">
                <div
                  className="card border-0 text-start bg-none"
                  data-aos="zoom-in"
                >
                  <div className="card-body pricing-card-style ">
                    {/* <div className="row">
                      <h2 className="mob-font-size" style={{ color: "black" }}>
                        <strong>
                          At Kodex, we develop advanced web services and
                          software solutions for you to succeed.
                        </strong>
                      </h2>
                    </div> */}

                    <p className="mob-dis-none md-mbl-font">
                      Use our genius software and web solutions customized
                      according to the changing needs of your business. Are you
                      looking for sources to improve your website? We at Kodex
                      will provide you with all the tools you need - for web
                      development, web design, graphic-logo design, E-Commerce,
                      and multimedia Solutions. Moreover, Kodex will provide all
                      the services and help you need with developing software.
                      We provide testing & QA, client-server solutions, desktop
                      application development, game development, system
                      programming, enterprise solutions, mobile solutions, and
                      porting.
                    </p>

                    {/* <Link
                      to="/contact-form"
                      className="btn btn-orange rounded-0 px-4 margin-0 margin-r-30 w-50"
                      style={{ textDecoration: "none" }}
                    >
                      GET STARTED
                    </Link> */}

                    {/* <OpenForm /> */}
                  </div>
                </div>
              </div>

              <div className="col " data-aos="zoom-in">
                <div
                  className="card border-0 text-start m-c-form car-box-shadow"
                  data-aos="zoom-in"
                >
                  <div className="card-body pricing-card-style ">
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    ></Box>
                    <form onSubmit={sendEmail} id="get-a-quote-form-header">
                      <div className="mx-2">
                        <div className="row row-cols-1">
                          <div className="card border-0 bg-none">
                            <div className="card-body ">
                              <div className="col p-r-5">
                                <TextField
                                  fullWidth
                                  sx={{ m: 1 }}
                                  className="pb-1"
                                  id="standard-textarea"
                                  label="Name"
                                  placeholder=""
                                  value={name}
                                  multiline
                                  onChange={(e) => setName(e.target.value)}
                                  name="name"
                                  required
                                  isInvalid
                                  variant="standard"
                                />
                              </div>

                              <div className="col p-r-5">
                                <TextField
                                  fullWidth
                                  sx={{ m: 1 }}
                                  className="pb-1"
                                  id="email"
                                  label="Email"
                                  placeholder=""
                                  value={email}
                                  onChange={(e) => validateEmail(e)}
                                  name="email"
                                  type="email"
                                  required
                                  isInvalid
                                  multiline
                                  variant="standard"
                                />
                                <span
                                  style={{
                                    // fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  {emailError}
                                </span>
                              </div>

                              <div className="col p-r-5">
                                <TextField
                                  fullWidth
                                  sx={{ m: 1 }}
                                  className="pb-1"
                                  id="standard-textarea"
                                  label="Phone Number"
                                  placeholder=""
                                  value={phoneNumber}
                                  multiline
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                  name="phoneNumber"
                                  type="number"
                                  required
                                  isInvalid
                                  variant="standard"
                                />
                              </div>

                              <div className="col p-r-5 pb-1">
                                <FormControl
                                  variant="standard"
                                  fullWidth
                                  sx={{ m: 1 }}
                                  // sx={{ m: 1, minWidth: 120 }}
                                >
                                  <InputLabel id="demo-simple-select-standard-label">
                                    Our Services
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    name="services"
                                    value={ourServices}
                                    onChange={handleChange}
                                    label="Our Services"
                                  >
                                    <MenuItem value={"Mobile App Development"}>
                                      Mobile Development
                                    </MenuItem>
                                    <MenuItem value={"Web Development"}>
                                      Web Development
                                    </MenuItem>
                                    <MenuItem value={"Enterprise Solutions"}>
                                      Graphic Designing
                                    </MenuItem>
                                    <MenuItem value={"FinTech Solutions"}>
                                      Enterprise Solutions
                                    </MenuItem>
                                    <MenuItem value={"Graphic Designing"}>
                                      FinTech Solutions
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="col p-r-5">
                                <TextField
                                  fullWidth
                                  sx={{ m: 1 }}
                                  className="pb-1 font-Poppins"
                                  id="standard-textarea"
                                  label="Subject"
                                  placeholder=""
                                  value={subject}
                                  multiline
                                  onChange={(e) => setSubject(e.target.value)}
                                  name="subject"
                                  required
                                  isInvalid
                                  variant="standard"
                                />
                              </div>

                              <div className="col p-r-5 mt-3">
                                <TextField
                                  fullWidth
                                  sx={{ m: 1 }}
                                  className="pb-1"
                                  id="message"
                                  label="Message"
                                  placeholder=""
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  name="message"
                                  type="message"
                                  multiline
                                  variant="standard"
                                />
                              </div>
                              <br />
                              <button
                                type="submit"
                                className="btn btn-orange rounded-0 px-4 margin-0 margin-r-30 w-50"
                                data-bs-toggle="modal"
                                style={{
                                  marginRight: "30px",
                                  height: "40px",
                                }}

                                // href="#exampleModalToggle"
                                // role="button"
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          sx={{ width: "100%" }}
                        >
                          Your form submitted successfully!
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        error={error}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          Please submit again!
                        </Alert>
                      </Snackbar>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Business;
