import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import About from "../components/About";
import AddOns from "../components/AddOns";
import Carousel from "../components/Carousel";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FormModal from "../components/modal/FormModal";
import OurServices from "../components/OurServices";
import Portfolio from "../components/Portfolio";
import TrustKodex from "../components/TrustKodex";

import "./home.css";

function ContactFormModal() {
  const [linkChange, setLinkChange] = useState("#contact-form");
  console.log("form link ------->", linkChange);
  const [backToTop, setBackToTop] = useState(false);

  useEffect(() => {
    function backTop() {
      if (window.scrollY <= 480) {
        setBackToTop(false);
      }
      if (window.scrollY >= 480) {
        setBackToTop(true);
      }
    }

    window.addEventListener("scroll", backTop);
    return () => window.addEventListener("scroll", backTop);
  }, []);
  return (
    <div className="font-Poppins">
      <div>
        {backToTop === true ? (
          <a
            href="#"
            id="toTopBtn"
            className="cd-top text-replace js-cd-top cd-top--is-visible cd-top--fade-out"
            data-abc="true"
          ></a>
        ) : null}

        <FormModal />

        <Header />

        <Carousel setLinkChange={setLinkChange} linkChange={linkChange} />
        <TrustKodex />
        <Portfolio />
        <AddOns />
        <About />
        <OurServices />
        <ContactForm />
        <Footer />
      </div>
    </div>
  );
}

export default ContactFormModal;
