import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import validator from "validator";

function FormModal() {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ourServices, setOurServices] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [emailError, setEmailError] = useState("");

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [carouselText, setCarouselText] = useState(false);
  const [carouselForm, setCarouselForm] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    setTimeout(() => {
      setCarouselText(true);
    }, 5000);
    setTimeout(() => {
      setCarouselForm(true);
    }, 7000);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleChange = (e) => {
    setOurServices(e.target.value);
  };

  const validateEmail = (e) => {
    var email = e.target.value;
    // console.log(validator.isEmail(email));
    setEmail(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      setEmailError("");
      setName("");
      setEmail("");
      setPhoneNumber("");
      setOurServices("");
      setSubject("");
      setMessage("");

      emailjs
        .sendForm(
          "service_syhdxfo",
          "template_gjiadh4",
          e.target,
          "0gLpe-VwWhjw4Rrc3"
        )
        .then(
          function (response) {
            // return setOpen(true);
            navigate("/thankyou");
            // console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            if (error) {
              // return setError(true)
              navigate("/*");
            }
            // console.log("FAILED...", error);
          }
        );
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  return (
    <div id="contactUs">
      <Link to="/">
        <div className="bg-modal"></div>
      </Link>
      <div className="modal-form">
        <div className="col modal-form-inner" data-aos="zoom-in">
          <div
            style={{ width: "100%" }}
            className="card border-0 text-start m-c-form car-box-shadow "
            data-aos="zoom-in"
          >
            <div className="card-body pricing-card-style ">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              ></Box>
              <form onSubmit={sendEmail} id="get-a-quote-form-header">
                <div className="mx-2">
                  <div className="row row-cols-1">
                    <div className="card border-0 bg-none">
                      <div className="card-body ">
                        <div className="col p-r-5">
                          <TextField
                            fullWidth
                            sx={{ m: 1 }}
                            className="pb-3"
                            id="standard-textarea"
                            label="Name"
                            placeholder=""
                            value={name}
                            multiline
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            required
                            isInvalid
                            variant="standard"
                          />
                        </div>

                        <div className="col p-r-5">
                          <TextField
                            fullWidth
                            sx={{ m: 1 }}
                            className="pb-3"
                            id="email"
                            label="Email"
                            placeholder=""
                            value={email}
                            onChange={(e) => validateEmail(e)}
                            name="email"
                            type="email"
                            required
                            isInvalid
                            multiline
                            variant="standard"
                          />
                          <span
                            style={{
                              // fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {emailError}
                          </span>
                        </div>

                        <div className="col p-r-5">
                          <TextField
                            fullWidth
                            sx={{ m: 1 }}
                            className="pb-3"
                            id="standard-textarea"
                            label="Phone Number"
                            placeholder=""
                            value={phoneNumber}
                            multiline
                            onChange={(e) => setName(e.target.value)}
                            name="phoneNumber"
                            required
                            isInvalid
                            variant="standard"
                          />
                        </div>

                        <div className="col p-r-5 pb-3">
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{ m: 1 }}
                            // sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Our Services
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="services"
                              value={ourServices}
                              onChange={handleChange}
                              label="Our Services"
                            >
                              <MenuItem value={"Mobile App Development"}>
                                Mobile Development
                              </MenuItem>
                              <MenuItem value={"Web Development"}>
                                Web Development
                              </MenuItem>
                              <MenuItem value={"Enterprise Solutions"}>
                                Graphic Designing
                              </MenuItem>
                              <MenuItem value={"FinTech Solutions"}>
                                Enterprise Solutions
                              </MenuItem>
                              <MenuItem value={"Graphic Designing"}>
                                FinTech Solutions
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col p-r-5">
                          <TextField
                            fullWidth
                            sx={{ m: 1 }}
                            className="pb-3 font-Poppins"
                            id="standard-textarea"
                            label="Subject"
                            placeholder=""
                            value={subject}
                            multiline
                            onChange={(e) => setSubject(e.target.value)}
                            name="subject"
                            required
                            isInvalid
                            variant="standard"
                          />
                        </div>

                        <div className="col p-r-5 mt-3">
                          <TextField
                            fullWidth
                            sx={{ m: 1 }}
                            className="pb-3"
                            id="message"
                            label="Message"
                            placeholder=""
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            name="message"
                            type="message"
                            multiline
                            variant="standard"
                          />
                        </div>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-orange rounded-0 px-4 margin-0 margin-r-30 w-50"
                          data-bs-toggle="modal"
                          style={{
                            marginRight: "30px",
                            height: "40px",
                          }}

                          // href="#exampleModalToggle"
                          // role="button"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Your form submitted successfully!
                  </Alert>
                </Snackbar>
                <Snackbar
                  error={error}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    Please submit again!
                  </Alert>
                </Snackbar>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormModal;
