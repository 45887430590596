import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import About from "../components/About";
import AddOns from "../components/AddOns";
import Business from "../components/Business";
import Carousel from "../components/Carousel";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FormModal from "../components/modal/FormModal";
import OpenForm from "../components/OpenForm";
import OurServices from "../components/OurServices";
import Portfolio from "../components/Portfolio";
// import Test from "../components/Test";
import TrustKodex from "../components/TrustKodex";
// import Tst from "../components/Tst";

import "./home.css";

function Home() {
  // const [formModalPop, setFormModalPop] = useState("#");
  // const [formModal, setFormModal] = useState(false);

  // console.log("form link ------->", formModalPop);
  // console.log("form Status ------->", formModal);
  const [backToTop, setBackToTop] = useState(false);

  useEffect(() => {
    function backTop() {
      if (window.scrollY <= 480) {
        setBackToTop(false);
      }
      if (window.scrollY >= 480) {
        setBackToTop(true);
      }
    }

    window.addEventListener("scroll", backTop);
    return () => window.addEventListener("scroll", backTop);
  }, []);

  // useEffect(() => {
  //   function modal() {
  //     if (formModal === true) {
  //       setFormModalPop("#contact-form");
  //     }
  //     if (formModal === false) {
  //       setFormModalPop("#");
  //     }
  //   }
  //   window.addEventListener("modal status", modal);
  //   return () => window.addEventListener("modal status", modal);
  // }, []);

  return (
    <div className="font-Poppins">
      <div>
        {backToTop === true ? (
          <a
            href="#"
            id="toTopBtn"
            className="cd-top text-replace js-cd-top cd-top--is-visible cd-top--fade-out"
            data-abc="true"
          ></a>
        ) : null}

        {/* {formModal === true ? <FormModal setFormModal={setFormModal} /> : null} */}

        <Header />
        <Carousel
        // setFormModalPop={setFormModalPop}
        // setFormModal={setFormModal}
        // formModalPop={formModalPop}
        // formModal={formModal}
        />
        <Business />
        <TrustKodex />
        <AddOns
        // setFormModalPop={setFormModalPop}
        />
        <About />
        {/* <Tst/> */}
        <Portfolio />
        {/* <Test/> */}
        <OurServices />
        <ContactForm />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
