import React, { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import AOS from "aos";
import "aos/dist/aos.css";
// enterprices
import e1e from "../assets/portfolio/enterprise/e1e.webp";
import e3e from "../assets/portfolio/enterprise/e3e.webp";
import e1 from "../assets/portfolio/enterprise/e-1.webp";
import e3 from "../assets/portfolio/enterprise/e-3.webp";
// import e4 from "../assets/portfolio/enterprise/e-4.webp";
// graphic
// import g1 from "../assets/portfolio/graphic/g-1.webp";
import g2g from "../assets/portfolio/graphic/g2g.webp";
import g3g from "../assets/portfolio/graphic/g3g.webp";
import g4g from "../assets/portfolio/graphic/g4g.webp";
import g10g from "../assets/portfolio/graphic/g10g.webp";
import g2 from "../assets/portfolio/graphic/g-2.webp";
import g3 from "../assets/portfolio/graphic/g-3.webp";
import g4 from "../assets/portfolio/graphic/g-4.webp";
import g5 from "../assets/portfolio/graphic/g-5.webp";
import g6 from "../assets/portfolio/graphic/g-6.webp";
import g7 from "../assets/portfolio/graphic/g-7.webp";
import g8 from "../assets/portfolio/graphic/g-8.webp";
// import g9 from "../assets/portfolio/graphic/g-9.webp";
import g10 from "../assets/portfolio/graphic/g-10.webp";
// mobile
import m2n from "../assets/portfolio/mbl/m2.jpg";
import m1m from "../assets/portfolio/mbl/m1m.webp";
import m2m from "../assets/portfolio/mbl/m2m.webp";
import m3m from "../assets/portfolio/mbl/Hab.jpg";
import m5m from "../assets/portfolio/mbl/m5m.webp";
import m6m from "../assets/portfolio/mbl/m6m.webp";
import m7m from "../assets/portfolio/mbl/m7m.webp";
import m8m from "../assets/portfolio/mbl/m8m.webp";
import m1 from "../assets/portfolio/mbl/m-1.webp";
import m2 from "../assets/portfolio/mbl/m-2.webp";
import m3 from "../assets/portfolio/mbl/haa.jpg";
import m4 from "../assets/portfolio/mbl/m-4.webp";
import m5 from "../assets/portfolio/mbl/m-5.webp";
import m6 from "../assets/portfolio/mbl/m-6.webp";
import m7 from "../assets/portfolio/mbl/m-7.webp";
import m8 from "../assets/portfolio/mbl/m-8.webp";
import m9 from "../assets/portfolio/mbl/cc-1.jpg";
import m10 from "../assets/portfolio/mbl/vh.jpg";
import m28 from "../assets/portfolio/mbl/28.jpg";
import m33 from "../assets/portfolio/mbl/33.jpg";
import m51 from "../assets/portfolio/mbl/51.jpg";
// website page

import w1w from "../assets/portfolio/web/w1w.webp";
import w2w from "../assets/portfolio/web/w2w.webp";
import w3w from "../assets/portfolio/web/w3w.webp";
import w4w from "../assets/portfolio/web/w4w.webp";
import w5w from "../assets/portfolio/web/w5w.webp";
import w1 from "../assets/portfolio/web/w-1.webp";
import w2 from "../assets/portfolio/web/w-2.webp";
import w3 from "../assets/portfolio/web/27.jpg";
import w4 from "../assets/portfolio/web/w-4.webp";
import w5 from "../assets/portfolio/web/w-5.webp";
import w6 from "../assets/portfolio/web/w-5.webp";
import w7 from "../assets/portfolio/web/w-5.webp";
import w8 from "../assets/portfolio/web/57.jpg";
import w77 from "../assets/portfolio/web/7.jpg";
import w17 from "../assets/portfolio/web/18.jpg";
import w23 from "../assets/portfolio/web/23.jpg";
import w41 from "../assets/portfolio/web/41.jpg";

function Portfolio() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });
  // const [basicActive, setBasicActive] = useState("tab1");
  const [toggler, setToggler] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [graphic, setGraphic] = useState(false);
  const [enterprise, setEnterprise] = useState(false);
  // ///////////////
  const [web1, setWeb1] = useState(false);
  const [web2, setWeb2] = useState(false);
  const [web3, setWeb3] = useState(false);
  const [web4, setWeb4] = useState(false);
  const [web5, setWeb5] = useState(false);
  const [web6, setWeb6] = useState(false);
  const [web7, setWeb7] = useState(false);
  const [web8, setWeb8] = useState(false);
  const [web77, setWeb77] = useState(false);
  const [web17, setWeb17] = useState(false);
  const [web23, setWeb23] = useState(false);
  const [web41, setWeb41] = useState(false);
  // ////////////////////
  const [mbl1, setMbl1] = useState(false);
  const [mbl2, setMbl2] = useState(false);
  const [mbl3, setMbl3] = useState(false);
  const [mbl4, setMbl4] = useState(false);
  const [mbl5, setMbl5] = useState(false);
  const [mbl6, setMbl6] = useState(false);
  const [mbl7, setMbl7] = useState(false);
  const [mbl8, setMbl8] = useState(false);
  const [mbl9, setMbl9] = useState(false);
  const [mbl10, setMbl10] = useState(false);
  const [mb28, setMb28] = useState(false);
  const [mb33, setMb33] = useState(false);
  const [mb51, setMb51] = useState(false);
  /////////////////////////////////
  const [ent1, setEnt1] = useState(false);
  const [ent2, setEnt2] = useState(false);
  const [ent3, setEnt3] = useState(false);
  // const [ent4, setEnt4] = useState(false);
  // /////////////////////////
  const [grap1, setGrap1] = useState(false);
  const [grap2, setGrap2] = useState(false);
  const [grap3, setGrap3] = useState(false);
  const [grap4, setGrap4] = useState(false);
  const [grap5, setGrap5] = useState(false);
  const [grap6, setGrap6] = useState(false);
  const [grap7, setGrap7] = useState(false);
  const [grap8, setGrap8] = useState(false);
  // const [grap9, setGrap9] = useState(false);

  return (
    <div id="portfolio">
      <div className="overflow-hidden pt-5">
        <div className="row text-center">
          <h2
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="400"
            data-aos-easing="linear"
            data-aos-delay="200"
            className="mob-font-size"
          >
            <strong>PORTFOLIO</strong>
          </h2>
        </div>
        <div className="container">
          <p>
            Kodex values client needs and considers the development of a website
            or a mobile application through the prism of the client's business
            tasks. Our goal is not just to develop a website, an online store,
            or a mobile application. and close the project - but to create a
            tool to achieve your business goals. Here are some masterpieces from
            our collection.
          </p>
        </div>
        <div>
          {/* <hr style={{ width: "100%", borderTop: "1px solid #03a0ff" }} /> */}
          <div className="container" id="portfolio">
            <div>
              <ul className="nav nav-tabs justify-content-center py-1 row row-cols-3 row-cols-lg-6">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#all"
                  >
                    All
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#web">
                    Web
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#mobile">
                    Mobile
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#enterprise"
                  >
                    Enterprise
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#graphics">
                    Graphics
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane container active" id="all">
                  <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-2 py-2">
                    <div className="col">
                      <button
                        data-aos="zoom-in"
                        onClick={() => setToggler(!toggler)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w1w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">BABEO</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={toggler}
                        sources={[w1]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="zoom-in"
                        onClick={() => setMobile(!mobile)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            // data-aos="zoom-in"
                            src={m3m}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              width: "300px",
                              background: "transparent",
                              height: "300px",
                              //
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">LUCKRENT</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                          //
                        }}
                        toggler={mobile}
                        sources={[m3]}
                      />
                      {/* <div className="col">
                        <img src={m1} alt="The Clifton Suspension Bridge" />
                      </div> */}
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-up"
                        onClick={() => setGraphic(!graphic)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g3g}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">KODEX HRMS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={graphic}
                        sources={[g3]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="zoom-in"
                        onClick={() => setEnterprise(!enterprise)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={e1e}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">KIDS LOCK APP</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={enterprise}
                        sources={[e1]}
                      />
                    </div>
                  </div>
                </div>

                <div className="tab-pane container fade" id="web">
                  <div className="row row-cols-1 row-cols-md-4 g-2 py-2 justify-content-around">
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setWeb1(!web1)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w1w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">BABEO</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web1}
                        sources={[w1]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb23(!web23)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w23}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">EMS WEBSITE</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web23}
                        sources={[w23]}
                      />
                    </div>
                    {/* <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb2(!web2)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w2w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                              // objectFit: "",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">SMART RIDER</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web2}
                        sources={[w2]}
                      />
                    </div> */}
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setWeb3(!web3)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w3}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                              objectFit: "fill",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">COUSTECK</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web3}
                        sources={[w3]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb17(!web17)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w17}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">DIGITAL MONEY COLLECTOR</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web17}
                        sources={[w17]}
                      />
                    </div>
                    {/* <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb4(!web4)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w5w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">DIGITAL MONEY COLLECTOR</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web4}
                        sources={[w4]}
                      />
                    </div> */}
                    {/* <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb5(!web5)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w4w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">AMIN TOP UPS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web5}
                        sources={[w5]}
                      />
                    </div> */}
                    {/* <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb6(!web6)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w4w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">CEAQUES ADMIN SIDE</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web6}
                        sources={[w6]}
                      />
                    </div> */}
                    {/* <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb7(!web7)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w4w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">Kajora</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web7}
                        sources={[w7]}
                      />
                    </div> */}

                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb77(!web77)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w77}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">CLEAQUES</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web77}
                        sources={[w77]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb41(!web41)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w41}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">KAJORA</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web41}
                        sources={[w41]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setWeb8(!web8)}
                        style={{
                          height: "300px",
                          border: "none",

                          background: "transparent",
                          // width: "300px",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w8}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">MANIFESTO</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={web8}
                        sources={[w8]}
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane container fade" id="mobile">
                  <div className="row row-cols-1 row-cols-md-4 justify-content-around g-2 py-2">
                    {/* <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setMbl1(!mbl1)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m2n}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">BINGLINE</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl1}
                        sources={[m2n]}
                      />
                    </div> */}
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl6(!mbl6)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m6m}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">CLEAQUES</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl6}
                        sources={[m6]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setMbl3(!mbl3)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m3m}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">HAB</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl3}
                        sources={[m3]}
                      />
                    </div>

                    {/* <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl4(!mbl4)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={w1w}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">BABEO</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl4}
                        sources={[m4]}
                      />
                    </div> */}
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl5(!mbl5)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m5m}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">SELLX</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl5}
                        sources={[m5]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl10(!mbl10)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m10}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">VOICE HER</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl10}
                        sources={[m10]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMb51(!mb51)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m51}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">LIVELI</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mb51}
                        sources={[m51]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl9(!mbl9)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m9}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">COMPASS COURIER</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl9}
                        sources={[m9]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl2(!mbl2)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m2m}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">GLITTER UPS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl2}
                        sources={[m2]}
                      />
                    </div>
                    {/* <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl8(!mbl8)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m8m}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">GRABIONS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl8}
                        sources={[m8]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMbl7(!mbl7)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m9}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">Compass Courier</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mbl7}
                        sources={[m7]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMb28(!mb28)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m28}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">EMS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mb28}
                        sources={[m28]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setMb33(!mb33)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m33}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">Glittterups</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={mb33}
                        sources={[m33]}
                      />
                    </div> */}
                  </div>
                </div>

                <div className="tab-pane container fade" id="enterprise">
                  <div className="row row-cols-1 row-cols-md-3 g-2 py-2">
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap2(!grap2)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g2g}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">COUSTEK</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap2}
                        sources={[g2]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setGrap3(!grap3)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g3g}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">KODEX HRMS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap3}
                        sources={[g3]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap4(!grap4)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g4g}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">MANIFESTO</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap4}
                        sources={[g4]}
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane container fade" id="graphics">
                  <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-2 py-2">
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap1(!grap1)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g10g}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">EATLOV FOOD APP</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap1}
                        sources={[g10]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setEnt1(!ent1)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={e1e}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">KIDS LOCK APP</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={ent1}
                        sources={[e1]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setEnt2(!ent2)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={m7m}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">DURJ</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={ent2}
                        sources={[m7]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-left"
                        onClick={() => setEnt3(!ent3)}
                        style={{
                          height: "300px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={e3e}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "300px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">SMART RIDER</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={ent3}
                        sources={[e3]}
                      />
                    </div>

                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap5(!grap5)}
                        style={{
                          height: "250px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g5}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "250px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">QASURI</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap5}
                        sources={[g5]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap6(!grap6)}
                        style={{
                          height: "250px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g6}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "250px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">PINK FLOSS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap6}
                        sources={[g6]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap7(!grap7)}
                        style={{
                          height: "250px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g7}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "250px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">JARDHARI BAKERS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap7}
                        sources={[g7]}
                      />
                    </div>
                    <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap8(!grap8)}
                        style={{
                          height: "250px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g8}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "250px",
                              border: "none",
                              width: "300px",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">DJUR EXPRESS</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        toggler={grap8}
                        sources={[g8]}
                      />
                    </div>
                    {/* <div className="col">
                      <button
                        data-aos="fade-right"
                        onClick={() => setGrap9(!grap9)}
                        style={{
                          height: "250px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <div className="overContainer">
                          <img
                            src={g9}
                            alt="The Clifton Suspension Bridge"
                            style={{
                              height: "250px",
                              border: "none",
                              background: "transparent",
                            }}
                          />
                          <div className="overlay">
                            <h3 className="text">JUNK UAE</h3>
                          </div>
                        </div>
                      </button>
                      <FsLightbox
                        style={{
                          width: "100%",
                          height: "300px",
                          
                        }}
                        toggler={grap9}
                        sources={[g9]}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
