import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import emailjs from "emailjs-com";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// import { Snackbar } from '@mui/material'
import MuiAlert from "@mui/material/Alert";

import { useNavigate } from "react-router-dom";

import validator from "validator";
// import ThankU from '../pages/ThankU.js/ThankU'

function ContactForm() {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ourServices, setOurServices] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [emailError, setEmailError] = useState("");

  // const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return
  //   }

  //   setOpen(false)
  // }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleChange = (e) => {
    setOurServices(e.target.value);
  };

  const validateEmail = (e) => {
    var email = e.target.value;
    // console.log(validator.isEmail(email));
    setEmail(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      setEmailError("");
      setName("");
      setEmail("");
      setPhoneNumber("");
      setOurServices("");
      setSubject("");
      setMessage("");

      emailjs
        .sendForm(
          "service_syhdxfo",
          // 'service_mudht07',
          "template_gjiadh4",
          // 'template_d8bes9c',
          e.target,
          // 'iG7VrKPbwKX1UsbKl',
          "0gLpe-VwWhjw4Rrc3"
        )
        .then(
          function () {
            // if (response.status === 200) {
            //   window.gtag("event", "conversion", {
            //     send_to: "AW-836156773/jft2CN3725EDEOX62o4D",
            //   });
            // }

            // if (response?.status === 200) {
            //   setOpen(true)
            // }
            // return navigate('/thankyou')
            navigate("/thankyou");
            // console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            if (error) {
              // return setError(true)
              navigate("/*");
            }
            // console.log("FAILED...", error);
          }
        );
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  return (
    <div id="contact">
      <div className="bg-lightgrey">
        <div
          className="container overflow-hidden"
          //   style={{ paddingTop: state === "pricing" ? "2rem" : "0px" }}
        >
          <div className="row row-cols-1 row-cols-md-2 align-items-center">
            <div className="col" data-aos="zoom-in">
              <div
                className="card border-0 text-start bg-none"
                data-aos="zoom-in"
              >
                <div className="card-body pricing-card-style bg-none ">
                  <div className="row pt-5">
                    <h2 className="mob-font-size">
                      <strong>CONTACT US</strong>
                    </h2>
                  </div>

                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  ></Box>
                  <form onSubmit={sendEmail} id="get-a-quote-form-footer">
                    <div className="row pt-2">
                      <div className="row row-cols-1">
                        <div className="card border-0 bg-none">
                          <div className="card-body ">
                            <div className="col p-r-5">
                              <TextField
                                fullWidth
                                sx={{ m: 1 }}
                                className="pb-3"
                                id="standard-textarea"
                                label="Name"
                                placeholder=""
                                value={name}
                                multiline
                                onChange={(e) => setName(e.target.value)}
                                name="name"
                                required
                                isInvalid
                                variant="standard"
                              />
                            </div>

                            <div className="col p-r-5">
                              <TextField
                                fullWidth
                                sx={{ m: 1 }}
                                className="pb-3"
                                id="email"
                                label="Email"
                                placeholder=""
                                value={email}
                                onChange={(e) => validateEmail(e)}
                                name="email"
                                type="email"
                                required
                                isInvalid
                                multiline
                                variant="standard"
                              />
                              <span
                                style={{
                                  // fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {emailError}
                              </span>
                            </div>
                            <div className="col p-r-5">
                              <TextField
                                fullWidth
                                sx={{ m: 1 }}
                                className="pb-3"
                                id="standard-textarea"
                                label="Phone Number"
                                placeholder=""
                                value={phoneNumber}
                                multiline
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                name="phoneNumber"
                                required
                                isInvalid
                                variant="standard"
                              />
                            </div>
                            <div className="col p-r-5 pb-3">
                              <FormControl
                                variant="standard"
                                fullWidth
                                sx={{ m: 1 }}
                                // sx={{ m: 1, minWidth: 120 }}
                              >
                                <InputLabel id="demo-simple-select-standard-label">
                                  Our Services
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  name="services"
                                  value={ourServices}
                                  onChange={handleChange}
                                  label="Our Services"
                                >
                                  <MenuItem value={"Mobile App Development"}>
                                    Mobile App Development
                                  </MenuItem>
                                  <MenuItem value={"Web Development"}>
                                    Web Development
                                  </MenuItem>
                                  <MenuItem value={"Enterprise Solutions"}>
                                    Enterprise Solutions
                                  </MenuItem>
                                  <MenuItem value={"FinTech Solutions"}>
                                    FinTech Solutions
                                  </MenuItem>
                                  <MenuItem value={"Graphic Designing"}>
                                    Graphic Designing
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col p-r-5">
                              <TextField
                                fullWidth
                                sx={{ m: 1 }}
                                className="pb-3"
                                id="standard-textarea"
                                label="Subject"
                                placeholder=""
                                value={subject}
                                multiline
                                onChange={(e) => setSubject(e.target.value)}
                                name="subject"
                                required
                                isInvalid
                                variant="standard"
                              />
                            </div>

                            <div className="col p-r-5 mt-3">
                              <TextField
                                fullWidth
                                sx={{ m: 1 }}
                                className="pb-3"
                                id="message"
                                label="Message"
                                placeholder=""
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                name="message"
                                type="message"
                                multiline
                                variant="standard"
                              />
                            </div>
                            <br />
                            <button
                              type="submit"
                              className="btn btn-orange rounded-0 px-4 margin-0 margin-r-30 w-50"
                              data-bs-toggle="modal"
                              style={{ marginRight: "30px", height: "40px" }}

                              // href="#exampleModalToggle"
                              // role="button"
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <ThankU /> */}
                    {/* <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: '100%' }}
                      >
                        Your form submitted successfully!
                      </Alert>
                    </Snackbar>
                    <Snackbar
                      error={error}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="error"
                        sx={{ width: '100%' }}
                      >
                        Please submit again!
                      </Alert>
                    </Snackbar> */}
                  </form>
                </div>
              </div>
            </div>
            <div className="col text-start">
              <div className="card bg-none border-0 ">
                <div className="card-body p-0">
                  <div className="text-center">
                    <img
                      className="mob-dis-none"
                      data-aos="zoom-in"
                      src={require("../assets/contact_us/map-vector-1.webp")}
                      alt="map-vector-1"
                    />
                    <h4 className="mb-2" style={{ color: "black" }}>
                      Follow Us :
                    </h4>
                    <ul className="list-unstyled m-0 text-center d-flex justify-content-center">
                      <a
                        className="link-light"
                        href="https://www.facebook.com/kodextech/"
                      >
                        <li className="ms-3" data-aos="zoom-in">
                          <FacebookIcon
                            className="social-icon-facebook"
                            style={{ fontSize: "44" }}
                          />
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://instagram.com/kodextechnologies?igshid=YmMyMTA2M2Y="
                      >
                        <li className="ms-3" data-aos="zoom-in">
                          <InstagramIcon
                            className="social-icon-instagram"
                            style={{ fontSize: "44" }}
                          />
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://twitter.com/TechKodex"
                      >
                        <li className="ms-3" data-aos="zoom-in">
                          <TwitterIcon
                            className="social-icon-twitter"
                            style={{ fontSize: "44" }}
                          />
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://www.linkedin.com/company/kodex-technologies/"
                      >
                        <li className="ms-3" data-aos="zoom-in">
                          <LinkedInIcon
                            className="social-icon-linkedin"
                            style={{ fontSize: "44" }}
                          />
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
