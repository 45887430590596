import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import OpenForm from "./OpenForm";

import { Link } from "react-router-dom";

function AddOns() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <div
    // id="pricing"
    //   style={{ paddingTop: state === "pricing" ? "2rem" : "0px" }}
    >
      {/* <OpenForm /> */}
      <div className="container overflow-hidden">
        <div className="row text-center py-5">
          <h2
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="400"
            data-aos-easing="linear"
            data-aos-delay="200"
            className="mob-font-size"
          >
            <strong>ADD ONS</strong>
          </h2>
        </div>
        <div className="row row-cols-1 row-cols-md-3">
          <div className="col">
            <div
              className="card mb-5 rounded-0 shadow-sm text-start"
              data-aos="zoom-in"
            >
              <div className="card-body pricing-card-style">
                <h4
                  className="card-title pricing-card-title text-purple"
                  data-aos="fade-right"
                >
                  Hourly Work
                </h4>
                <hr style={{ border: "1px solid #03A0FF" }} />

                <ul className="list-styled mt-3 mb-4">
                  <li>Suitable for small tasks</li>
                  <li>Free Research</li>
                  <li>Demo available for use</li>
                  <li>Limited sales support</li>
                  <li>Limited QA Hours</li>
                </ul>
              </div>
              <div className="m-2">
                <a
                // href="#contact-us"
                //    onClick={() => setState("contact-us")}
                >
                  <Link
                    className="w-100 btn btn-lg btn-orange rounded-0"
                    to="/contact-form"
                    style={{ textDecoration: "none" }}
                  >
                    GET STARTED
                  </Link>
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card mb-5 card-body-orange rounded-0 shadow-sm text-start"
              data-aos="zoom-in"
            >
              <div className="card-body pricing-card-style">
                <h4
                  className="card-title pricing-card-title"
                  data-aos="fade-right"
                  style={{ color: "#FFFFFF" }}
                >
                  Monthly Dedicated
                </h4>
                <hr style={{ border: "1px solid #ffffff" }} />
                <ul className="list-styled mt-3 mb-4">
                  <li>40 hours per week</li>
                  <li>Dedicated Resource</li>
                  <li>Development Manager</li>
                  <li>12 hours of sales support</li>
                  <li>QA included</li>
                </ul>
              </div>
              <div className="m-2">
                <Link
                  className="w-100 btn btn-lg btn-white rounded-0"
                  to="/contact-form"
                  style={{ textDecoration: "none" }}
                >
                  GET STARTED
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card mb-5 rounded-0 shadow-sm text-start"
              data-aos="zoom-in"
            >
              <div className="card-body pricing-card-style">
                <h4
                  className="card-title pricing-card-title text-purple"
                  data-aos="fade-right"
                >
                  Project Based
                </h4>
                <hr style={{ border: "1px solid #03A0FF" }} />
                <ul className="list-styled mt-3 mb-4">
                  <li>Documentation oriented </li>
                  <li>Bi-weekly meetings</li>
                  <li>Development Manager</li>
                  <li>Flexible hours</li>
                  <li>After-sales support</li>
                </ul>
              </div>
              <div className="m-2">
                <a
                // href="#contact-us"
                //    onClick={() => setState("contact-us")}
                >
                  <Link
                    className="w-100 btn btn-lg btn-orange rounded-0"
                    to="/contact-form"
                    style={{ textDecoration: "none" }}
                  >
                    GET STARTED
                  </Link>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOns;
