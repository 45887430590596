import React from "react";
import "./index.css";

function Carousel() {
  return (
    <div className="style-carousel" id="home">
      {/* <OpenForm /> */}

      <div id="myCarousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <video
              className="background-video carousel-img-overlay"
              width={"100%"}
              autoPlay
              playsInline
              loop
              muted
            >
              <source
                src={require("../assets/carousel/Kodex.mp4")}
                type="video/mp4"
              />
            </video>
            <div className="carousel-caption text-dark text-center p-0">
              <h2 className="mob-font-size" style={{ color: "black" }}>
                <strong>
                  We develop advanced web services and <br /> software solutions
                  for you to succeed.
                </strong>
              </h2>
              <a
                href="#business"
                className="btn btn-orange rounded-0 px-4 margin-0 margin-r-30 w-25 btn-100"
                style={{ textDecoration: "none" }}
              >
                GET STARTED
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
