import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";

import "./index.css";

function Footer() {
  return (
    <div className="bg-lightgrey">
      <div className="container">
        <footer className="py-5">
          <div className="row row-cols-1 row-cols-md-4 text-start">
            <div className="col mb-3">
              <h4>
                <img
                  src={require("../assets/logo/kodex-logo.webp")}
                  alt="kodex-logo"
                  width={120}
                />
              </h4>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a
                    href="https://goo.gl/maps/PjGwwnj6zvms1e2n6"
                    className="nav-link border-0 p-0 text-dark text-start d-flex mob-style-ft-lo"
                  >
                    <LocationOnIcon className="color-orange-icon" />
                    <lable className="ms-2 ml-mob-0">
                      46 K DHA Phase 1 Lahore, Punjab Pakistan
                    </lable>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    href="mailto:info@kodextech.com"
                    className="nav-link border-0 p-0 text-dark text-start"
                  >
                    <MailIcon className="color-orange-icon" />
                    <lable className="ms-2">info@kodextech.com</lable>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    href="tel:+924237207150"
                    className="nav-link border-0 p-0 text-dark text-start"
                  >
                    <PhoneIcon className="color-orange-icon" />
                    <lable className="ms-2">+92 42 372 07 150</lable>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col mb-3">
              <h4>Useful Links</h4>
              <ul className="nav flex-column">
                <li className="nav-item mb-2 list-styled">
                  <a href="/#" className="nav-link p-0 text-muted">
                    Home
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#about" className="nav-link p-0 text-muted">
                    About Us
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#services" className="nav-link p-0 text-muted">
                    Services
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#portfolio" className="nav-link p-0 text-muted">
                    Portfolio
                  </a>
                </li>

                <li className="nav-item mb-2">
                  <a href="#contact" className="nav-link p-0 text-muted">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="col mb-3">
              <h4>Our Services</h4>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a href="#webDevelopment" className="nav-link p-0 text-muted">
                    Web Development
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#mobileApp" className="nav-link p-0 text-muted">
                    Mobile Application Development
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    href="#graphicDesigning"
                    className="nav-link p-0 text-muted"
                  >
                    Graphic Designing
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#fineTech" className="nav-link p-0 text-muted">
                    FinTech Solutions
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#enterprise" className="nav-link p-0 text-muted">
                    Enterprise Solutions
                  </a>
                </li>
              </ul>
            </div>

            <div className="col mb-3">
              <form>
                <h4>Join Our Newsletter</h4>
                <p>Monthly digest of what's new and exciting from us.</p>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  <label for="newsletter1" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="newsletter1"
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                  />
                  <button className="btn btn-orange rounded-0" type="button">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
          <hr />

          <div className="row">
            <p className="card-text text-dark">
              &copy; Copyright {""}
              <a className="ft-nav-link" href="/">
                Kodex Technologies
              </a>{" "}
              All Right Reserved. Powered by {""}
              <a className="ft-nav-link" href="https://optiwisesolutions.com/">
                Optiwise Solutions
              </a>{" "}
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
