import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

function About() {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  })
  return (
    <div id="about">
      <div className="bg-lightgrey">
        <div
          className="container overflow-hidden"
          //   style={{ paddingTop: state === "pricing" ? "2rem" : "0px" }}
        >
          <div className="row row-cols-1 row-cols-md-2 align-items-center">
            <div className="col">
              <div className="card mb-5 border-0 text-start bg-none">
                <div className="card-body pricing-card-style ">
                  <div className="row py-5">
                    <h2
                      className="mob-font-size"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <strong>ABOUT US</strong>
                    </h2>
                  </div>

                  <p data-aos="fade-up">
                    Kodex Technologies is a well-established and fast-growing
                    Software and Web Development Company providing Mobile
                    Software and Web Development services to our valuable
                    clients across the globe without compromising eminence. We
                    are a highly structured, performance-oriented, and
                    customer-centric organization. We deliver strategic
                    end-to-end solutions to our esteemed clients. We develop
                    digital and software solutions, which makes us a part of our
                    client’s success in their business, hence enabling them an
                    intelligible edge.
                  </p>
                </div>
              </div>
            </div>
            <div className="col mb-5">
              <img
                src={require('../assets/about_us/about_us_1.webp')}
                width="100%"
                height="100%"
                data-aos="fade-left"
                alt="about_us_1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
