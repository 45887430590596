import React from "react";

function TrustKodex() {
  return (
    <div id="trust-kodex">
      <div
        className="container pt-5 overflow-hidden"
        //   style={{ paddingTop: state === "pricing" ? "2rem" : "0px" }}
      >
        <div className="row row-cols-1 row-cols-md-2 align-items-center">
          <div className="col">
            <div
              className="card mb-5 border-0 text-start bg-none"
              data-aos="zoom-in"
            >
              <div className="card-body pricing-card-style ">
                <div className="row py-5">
                  <h2 className="mob-font-size">
                    <strong>WHY TRUST KODEX?</strong>
                  </h2>
                  <p className="m-0">
                    Get a complete view of the endless possibilities at Kodex
                    with our superior services.
                  </p>
                </div>

                <p>
                  <button
                    className="btn btn-orange w-100"
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target="#collapseExample1"
                    // aria-expanded="false"
                    // aria-controls="collapseExample1"
                  >
                    Expedite Your Digital Transformation
                  </button>
                </p>
                {/* <div className="collapse" id="collapseExample1">
                  <div className="card card-body">
                    Some placeholder content for the collapse component. This
                    panel is hidden by default but revealed when the user
                    activates the relevant trigger.
                  </div>
                </div> */}
                <p>
                  <button
                    className="btn btn-orange mt-3 w-100"
                    type="button2"
                    // data-bs-toggle="collapse"
                    // data-bs-target="#collapseExample2"
                    // aria-expanded="false"
                    // aria-controls="collapseExample2"
                  >
                    Optimize Customer Journey
                  </button>
                </p>
                {/* <div className="collapse" id="collapseExample2">
                  <div className="card card-body">
                    Some placeholder content for the collapse component. This
                    panel is hidden by default but revealed when the user
                    activates the relevant trigger.
                  </div>
                </div> */}
                <h3>
                  <button
                    className="btn btn-orange mt-3 w-100"
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target="#collapseExample3"
                    // aria-expanded="false"
                    // aria-controls="collapseExample3"
                  >
                    Earn the Best ROI
                  </button>
                </h3>
                {/* <div className="collapse" id="collapseExample3">
                  <div className="card card-body">
                    Some placeholder content for the collapse component. This
                    panel is hidden by default but revealed when the user
                    activates the relevant trigger.
                  </div>
                </div> */}
                <p>
                  <button
                    className="btn btn-orange mt-3 w-100"
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target="#collapseExample4"
                    // aria-expanded="false"
                    // aria-controls="collapseExample4"
                  >
                    A Complete and Relaible Solution
                  </button>
                </p>
                {/* <div className="collapse" id="collapseExample4">
                  <div className="card card-body">
                    Some placeholder content for the collapse component. This
                    panel is hidden by default but revealed when the user
                    activates the relevant trigger.
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col mb-5">
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col mob-dis-none">
                <img
                  src={require("../assets/trust_kodex/trust-kodex-1.webp")}
                  width="65%"
                  height="auto"
                  style={{ borderRadius: "10px" }}
                  alt="trust-kodex-1"
                />
              </div>
              <div className="col mob-dis-none">
                <img
                  src={require("../assets/trust_kodex/trust-kodex-2.webp")}
                  width="65%"
                  height="auto"
                  alt="trust-kodex-2"
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-1">
              <div className="col mob-dis-none">
                <img
                  src={require("../assets/trust_kodex/trust-kodex-3.webp")}
                  width="40%"
                  height="auto"
                  style={{
                    borderRadius: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  alt="trust-kodex-3"
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col mob-dis-none">
                <img
                  src={require("../assets/trust_kodex/trust-kodex-4.webp")}
                  width="65%"
                  height="auto"
                  alt="trust-kodex-4"
                />
              </div>
              <div className="col">
                <img
                  src={require("../assets/trust_kodex/trust-kodex-5.webp")}
                  width="65%"
                  height="auto"
                  alt="trust-kodex-5"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <p
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="400"
            data-aos-easing="linear"
            data-aos-delay="200"
          >
            Meet the challenges of tomorrow's information age with our
            revolutionary web services and software solutions - customized
            solutions for your business processes.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TrustKodex;
