import "./App.css";
import Home from "./pages/Home";
import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ThankU from "./pages/ThankU.js/ThankU";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ContactFormModal from "./pages/ContactFormModal";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-form" element={<ContactFormModal />} />
        <Route path="/thankyou" element={<ThankU />} />
        <Route path="/*" element={<PageNotFound />} />
        {/* <Route path="/" element={open === false ? <Home /> : <ThankU />} />
        <Route
          path="/thankyou"
          element={
            open === true ? <Navigate to="/thankyou" replace /> : <Home />
          }
        /> */}

        {/* <Route path="contact" element={<OpenForm/>} /> */}
      </Routes>
    </div>
  );
}

export default App;
