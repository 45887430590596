import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function OurServices() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });
  return (
    <div id="services">
      <div className="overflow-hidden position-relative">
        <div className="container">
          <div className="row text-center pt-5" id="service">
            <h2
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="400"
              data-aos-easing="linear"
              data-aos-delay="200"
            >
              <strong>OUR SERVICES</strong>
            </h2>
            <p
              className="px-5"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="400"
              data-aos-easing="linear"
              data-aos-delay="200"
            >
              Access the full spectrum of services to help your organization
              work properly - from creating standards of excellence to training
              your employees to work efficiently, from assessing how well your
              business is doing and planning strategies to improve profits in
              the future. Kodex has been offering unique, premium, and special
              services for several years while prioritizing quality.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-3 g-4 py-5 justify-content-around">
            <div className="col" id="webDevelopment">
              <div className="card bg-none border-0 align-items-center h-100 card-hov">
                <img
                  src={require("../assets/our_services/web-development.webp")}
                  width="25%"
                  height="auto"
                  className="pt-3"
                  data-aos="zoom-in"
                  alt="web-development"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    <strong>Web Development</strong>
                  </h5>
                  <p
                    className="card-text"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    A multitude of web development services, including
                    developmental tools and techniques offered at Kodex - from
                    the development of plain to dynamic web pages - all web
                    development services are rendered efficiently for your ease.
                  </p>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="col" id="mobileApp">
              <div className="card bg-none border-0 align-items-center h-100 card-hov">
                <img
                  src={require("../assets/our_services/mobile-application-development.webp")}
                  width="25%"
                  height="auto"
                  className="py-3"
                  data-aos="zoom-in"
                  alt="mobile-application-development"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    <strong>Mobile Application Development</strong>
                  </h5>
                  <p
                    className="card-text"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    Evolve your business in the digital landscape by developing
                    a visually attractive mobile app for your business using our
                    excellent mobile app development services - including all
                    the necessary tools for research, design, development,
                    testing, and maintenance. Ensure the survival and success of
                    your company by using these helpful tools.
                  </p>
                </div>
              </div>
            </div>
            <div className="col" id="graphicDesigning">
              <div className="card bg-none border-0 align-items-center h-100 card-hov">
                <img
                  src={require("../assets/our_services/graphic-designing.webp")}
                  width="25%"
                  height="auto"
                  className="py-3"
                  data-aos="zoom-in"
                  alt="graphic-designing"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    <strong>Graphic Designing</strong>
                  </h5>
                  <p
                    className="card-text"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    Kodex believes that an excellent graphic design will help
                    your firm gain attention, which will help the company to
                    grow. Attractive aesthetics, efficient concept presentation,
                    and higher reliability are all dependent on a graphic design
                    to attract visitors to your brand.
                  </p>
                  <br />
                </div>
              </div>
            </div>
            <div className="col" id="fineTech">
              <div className="card bg-none border-0 align-items-center h-100 card-hov">
                <img
                  src={require("../assets/our_services/fintech-solutions.webp")}
                  width="25%"
                  height="auto"
                  className="py-3"
                  data-aos="zoom-in"
                  alt="fintech-solutions"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    <strong>FinTech Solutions</strong>
                  </h5>
                  <p
                    className="card-text"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    From large corporations to micro-sized enterprises, we
                    satisfy all sorts of organizational needs with our
                    Enterprise Application Development. Our I.T ninjas can
                    develop solution-oriented business tools to address your
                    unique demands through our first-rate FinTech solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col" id="enterprise">
              <div className="card bg-none border-0 align-items-center h-100 card-hov">
                <img
                  src={require("../assets/our_services/enterprise-solutions.webp")}
                  width="25%"
                  height="auto"
                  className="pt-3"
                  data-aos="zoom-in"
                  alt="enterprise-solutions"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    <strong>Enterprise Solutions</strong>
                  </h5>
                  <p
                    className="card-text"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="400"
                    data-aos-easing="linear"
                    data-aos-delay="200"
                  >
                    We provide a mix of enterprise mobility solutions to meet
                    the telework and remote-work needs of your business. Your
                    employees do not need to be stuck in the four walls of the
                    office anymore to follow your instructions and complete
                    their projects effectively. Our helpful tools will help you
                    customize your application to align with your business team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
