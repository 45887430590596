import React, { useState, useEffect } from "react";

function Header() {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <div className={navbar ? "bg-light fixed-top" : "fixed-top"}>
      <nav
        className={"container navbar navbar-expand-lg nav-height"}
        style={{ height: "80px" }}
      >
        <div className="container-fluid bg-white-mb">
          <a className="navbar-brand" href="#home">
            <h1>
              <img
                src={require("../assets/logo/kodex-logo.webp")}
                alt="kodex-logo"
                width={"85px"}
              />
            </h1>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse  w-100  justify-content-end"
            id="navbarSupportedContent"
          >
            <form className="d-flex justify-content-center" role="search">
              <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="#home"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#portfolio">
                    Portfolio
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#services">
                    Services
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    Contact
                  </a>
                </li>
                {/* <li>
                  <button className="btn btn-orange" type="button">
                    Get a Quote
                  </button>
                </li> */}
              </ul>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
